import $ from 'jquery'
import {download } from '@/module/utils/download.js'


const url = {
    bind(el) {
        $(el).on('click', 'a[data-url-download]', function () {
            let $this = $(this)
            let href = $this.attr('data-url-download')
            download(href )
        })
    },
    unbind(el) {
        $(el).off('click', 'a[data-url-download]')
    }
}

const insert = {
    bind(el,binding) {
        const element = document.createElement(binding.value.tagName||'element')
        if (binding.value.attrs) {
            $(element).attr(binding.value.attrs)            
        }
        $(el).append(element)
    }
}

const http = {
    bind(el,binding) {
        const pattern = new RegExp('(http|ftp|https):\\/\\/[\\w\\-_]+(\\.[\\w\\-_]+)+([\\w\\-\\.,@?^=%&amp;:/~\\+#]*[\\w\\-\\@?^=%&amp;/~\\+#])?')
        if (pattern.test(binding.value)) {
            const element = document.createElement('a')
            element.href = binding.value
            element.target='_blank'
            element.innerText = binding.value
            $(el).append(element)
        } else  {
            el.innerText = binding.value
        }

    },
    update(el,binding) {
        if (el.children[0]) {
            el.children[0].href = binding.value
            el.children[0].innerText = binding.value
        } else {
            el.innerText = binding.value
        }
    }

}


export {url,insert,http}
