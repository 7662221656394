import '@babel/polyfill'
import Vue from 'vue'
import Box from '@dbm/box'
import FyVueSkeleton from "fy-vue-skeleton"
import './plugins/vuetifyExt'
import './plugins/http'
import './plugins/filters'
import './plugins/sdk'
import App from './App.vue'
import router from './router'
import store from './store'
// import VueMeta from 'vue-meta'
// import i18n from './i18n'
import 'font-awesome/css/font-awesome.min.css'
import "fy-vue-skeleton/lib/fy-vue-skeleton.css"
import '@/css/base/index.scss'


Vue.config.productionTip = false

// Vue.use(VueMeta, {
//     refreshOnceOnNavigation: true
// })
Vue.use(Box)
Vue.use(FyVueSkeleton)
Vue.prototype.$message.config({
    top: 120,
    duration: 5
})


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
