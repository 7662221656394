const app_key = process.env.VUE_APP_KEY
const server_name1 = process.env.VUE_APP_SERVER_NAME1
const server_name2 = process.env.VUE_APP_SERVER_NAME2
const limitSize = {
	errMsg: 'File size exceeds 20M',
	size: 1024 * 1024 * 20
}

const isDev = process.env.NODE_ENV === 'development'
const shellURL = process.env.VUE_APP_SHELL
// process.env.NODE_ENV !== 'development'

const cloudclustersURL = isDev ? 'http://cloudclusters.clusteredclouds.com' : 'https://www.cloudclusters.io'
const colorMap = {
	Active: '#10c610',
	Suspended: '#495ab9',
	Cancelled: '#999999',
	'Deploy Failed': '#FF5252',
	Pending: '#FFC107',
	'Active-bg': '#E1FFE1',
	'Suspended-bg': '#e0e5ff',
	'Cancelled-bg': '#cccccc',
	'Deploy Failed-bg': '#FFE0E0',
	'Pending-bg': '#FFF0E0'
}
const products = {
	magento: {
		logo: require('@/assets/images/products/magento.png'),
		logo_notext: require('@/assets/images/product_notext/magento.png'),
		productName: 'Magento',
		category: 'Ecom',
		title:"Magento Ecom",
		docs: 'https://www.mgtclusters.com/docs/magento/',
		url: 'https://www.mgtclusters.com',
		dev_url: 'http://mgtclusters.clusteredclouds.com',
	},
	opencart: {
		logo: require('@/assets/images/products/opencart.png'),
		logo_notext: require('@/assets/images/product_notext/opencart.png'),
		productName: 'OpenCart',
		category: 'Ecom',
		title:"OpenCart Ecom",
		docs: 'https://docs.cloudclusters.io/opencart/',
		url: 'https://www.occlusters.com',
		dev_url: 'http://occlusters.clusteredclouds.com',
	},
	wordpress: {
		logo: require('@/assets/images/products/wordpress.png'),
		logo_notext: require('@/assets/images/product_notext/wordpress.png'),
		productName: 'WordPress',
		category: 'CMS',
		title:"WordPress CMS",
		docs: 'https://www.wp-clusters.com/docs/wordpress/',
		url: 'https://www.wp-clusters.com',
		dev_url: 'http://wp-clusters.clusteredclouds.com',
	},
	drupal: {
		logo: require('@/assets/images/products/drupal.png'),
		logo_notext: require('@/assets/images/product_notext/drupal.png'),
		productName: 'Drupal',
		category: 'CMS',
		title:'Drupal CMS',
		docs: 'https://www.dp-clusters.com/docs/drupal/',
		url: 'https://www.dp-clusters.com',
		dev_url: 'http://dp-clusters.clusteredclouds.com',
	},
	mysql: {
		logo: require('@/assets/images/products/mysql.png'),
		logo_notext: require('@/assets/images/product_notext/mysql.png'),
		productName: 'Mysql',
		category: 'Database',
		title:'Mysql Database',
		docs: 'https://www.mariaclusters.com/docs/mysql/',
		url: 'https://www.sqlclusters.com',
		dev_url: 'http://sqlclusters.clusteredclouds.com',
	},
    oracle: {
        logo: require('@/assets/images/products/oracle.webp'),
        logo_notext: require('@/assets/images/product_notext/oracle_notext.webp'),
        productName: 'oracle',
        category: 'Database',
        title:'Oracle',
        docs: 'https://www.oclusters.com/docs/oracle/',
        url: 'https://www.oclusters.com/',
        dev_url: 'https://www.oraclusters.clusteredclouds.com',
    },
	postgresql: {
		logo: require('@/assets/images/products/postgresql.png'),
		logo_notext: require('@/assets/images/product_notext/postgresql.png'),
		productName: 'PostgreSQL',
		category: 'Database',
		title:'PostgreSQL Database',
		docs: 'https://www.pgsclusters.com/docs/postgresql/',
		url: 'https://www.pgsclusters.com',
		dev_url: 'http://pgsclusters.clusteredclouds.com',
	},
	cockroachdb: {
		logo: require('@/assets/images/products/cockroachdb.png'),
		logo_notext: require('@/assets/images/product_notext/cockroachdb.png'),
		productName: 'CockroachDB',
		category: 'Database',
		title:'CockroachDB Database',
		docs: 'https://www.cclusters.com/docs/cockroachdb/',
		url: 'https://www.cclusters.com/',
		dev_url: 'http://cockroachclusters.clusteredclouds.com',
	},
	mariadb: {
		logo: require('@/assets/images/products/mariadb.png'),
		logo_notext: require('@/assets/images/product_notext/mariadb.png'),
		productName: 'MariaDB',
		category: 'Database',
		title:'MariaDB Database',
		docs: 'https://www.maclusters.com/docs/mariadb/',
		url: 'https://www.maclusters.com/',
		dev_url: 'http://mariaclusters.clusteredclouds.com',
	},
	mongodb: {
		logo: require('@/assets/images/products/mongodb.png'),
		logo_notext: require('@/assets/images/product_notext/mongodb.png'),
		productName: 'MongoDB',
		category: 'Database',
		title:'MongoDB Database',
		docs: 'https://www.mongodb.com/docs/mongodb/',
		url: 'https://www.m-clusters.com',
		dev_url: 'http://mongodb.clusteredclouds.com',
	},
	'sql server': {
		logo: require('@/assets/images/products/mssql.png'),
		logo_notext: require('@/assets/images/product_notext/mssql.png'),
		productName: 'SQL Server',
		category: 'Database',
		title:'SQL Server Database',
		docs: 'https://www.msclusters.com/docs/sqlserver/',
		url: 'https://www.msclusters.com/',
		dev_url: 'http://ssclusters.clusteredclouds.com/',
	},
	redis: {
		logo: require('@/assets/images/products/redis.png'),
		logo_notext: require('@/assets/images/product_notext/redis.png'),
		productName: 'Redis',
		category: 'Database',
		title:'Redis Database',
		docs: 'https://www.rclusters.com/docs/redis/',
		url: 'https://www.rclusters.com/',
		dev_url: 'http://rdsclusters.clusteredclouds.com',
	},
	phpclusters: {
		logo: require('@/assets/images/products/phpApache.png'),
		logo_notext: require('@/assets/images/product_notext/phpApache.png'),
		productName: 'PhpClusters',
		category: 'Runtimes',
		title:'Php & Apache Runtime Environment',
	},
	javaclusters: {
		logo: require('@/assets/images/products/javaClusters.png'),
		logo_notext: require('@/assets/images/product_notext/javaClusters.png'),
		productName: 'JavaClusters',
		category: 'Runtimes',
		title:'Java & Tomcat Runtime Environment',
	},
	pythonclusters: {
		logo: require('@/assets/images/products/pythonClusters.png'),
		logo_notext: require('@/assets/images/product_notext/pythonClusters.png'),
		productName: 'PythonClusters',
		category: 'Runtimes',
		title:'Python & uWSGI Runtime Environment',
	},
	aspnetclusters: {
		logo: require('@/assets/images/products/aspnetClusters.png'),
		logo_notext: require('@/assets/images/product_notext/aspnetClusters.png'),
		productName: 'AspnetClusters',
		category: 'Runtimes',
		title:'ASP.NET & Kestrel Runtime Environment',
	},
    nodejsclusters: {
        logo: require('@/assets/images/products/nodejsClusters.png'),
        logo_notext: require('@/assets/images/product_notext/nodejsClusters.png'),
        productName: 'NodejsClusters',
        category: 'Runtimes',
        title:'Node.js & Kestrel Runtime Environment',
    },
    odoo: {
        logo: require('@/assets/images/products/odoo.webp'),
        logo_notext: require('@/assets/images/product_notext/odoo_notext.webp'),
        productName: 'odoo',
        category: 'CRM',
        title:'Odoo',
        docs: 'https://www.odclusters.com/docs/odoo/',
        url: 'https://www.odclusters.com',
        dev_url: 'http://odclusters.clusteredclouds.com',

    },
    moodle: {
        logo: require('@/assets/images/products/moodle.png'),
        logo_notext: require('@/assets/images/product_notext/moodle.png'),
        productName: 'moodle',
        category: 'Education',
        title:'Odoo',
        docs: 'https://www.moclusters.com/docs/moodle/',
        url: 'https://www.moclusters.com',
        dev_url: 'https://www.moclusters.clusteredclouds.com',

    },
    elasticsearch: {
        logo: require('@/assets/images/products/elasticsearch.png'),
        logo_notext: require('@/assets/images/product_notext/elasticsearch_notext.webp'),
        productName: 'elasticsearch',
        category: 'Database',
        title:'Elasticsearch',
        docs: 'https://www.esclusters.com/docs/elasticsearch/',
        url: 'https://www.esclusters.com/',
        dev_url: 'https://www.elastic-clusters.clusteredclouds.com',

    },
	kafka: {
		logo: require('@/assets/images/products/kafka.png'),
		logo_notext: require('@/assets/images/product_notext/kafka.png'),
		productName: 'Kafka',
		category: 'Message Queue',
		title:'Kafka',
		docs: 'https://www.kaclusters.com/docs/kafka/',
		url: 'https://www.kaclusters.com/',
		dev_url: 'https://kaclusters.clusteredclouds.com/',

	},
    prometheus: {
        logo: require('@/assets/images/products/prometheus200.png'),
        logo_notext: require('@/assets/images/product_notext/prometheus100.png'),
        productName: 'Prometheus',
        category: 'Operations',
        title:'Prometheus',
        docs: 'https://www.pclusters.com/docs/prometheus/',
        url: 'https://www.pclusters.com/',
        dev_url: 'https://kaclusters.clusteredclouds.com/',

    },
	cassandra: {
		logo: require('@/assets/images/products/cassandra.png'),
		logo_notext: require('@/assets/images/product_notext/cassandra.png'),
		productName: 'Cassandra',
		category: 'Database',
		title:'Cassandra',
		docs: 'https://www.caclusters.com/docs/cassandra/',
		url: 'https://www.caclusters.com/',
		dev_url: 'https://kaclusters.clusteredclouds.com/',
	}
}

const domain = {
	SSO: process.env.VUE_APP_SSO
}
const status = {
	Pending: {},
	Active: {},
	Suspended: {},
	Cancelled: {}
}

const packageCategory = ['Database', 'CMS']
const packageStatus = ['Pending', 'Active', 'Suspended', 'Cancelled']
const deployStatus = ['Deploying', 'Success', 'Failure', 'Timeout', 'Deleting', 'Deleted', 'DeleteFailed']

const cancellationTypes = ['Immediate', 'End of Billing Cycle']


export {
	colorMap,
	shellURL,
	cloudclustersURL,
	isDev,
	products,
	domain,
	status,
	packageCategory,
	packageStatus,
	deployStatus,
	cancellationTypes,
	app_key,
	server_name1,
	server_name2,
	limitSize
}
