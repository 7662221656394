import mixpanel from 'mixpanel-browser';
import Vue from 'vue'


// Vue.use(VueMatomo, {
//     host: 'https://analytics.cloudclusters.io/',
//     siteId: 15,
//     // router: router,
//     enableLinkTracking: true,
//     requireConsent: false,
//     trackInitialView: true,
//     disableCookies: false,
//     debug: false,
//     userId: undefined,
//     preInitActions: []
// });
let token = process.env.VUE_CLI_MODERN_MODE === 'release'?'b76feb9751bdfc6bdcf1e3b9def23661':'1fa7416d060f621898ff2bec56bd8309'

mixpanel.init(token);



// const Events = {
//     'PageView':{
//         defaultOptions (){
//             return {
//                 pageUrl:window.location.href,
//                 pageName:'',
//                 productName:''
//             }
//         }
//     },
//     'Click' : {
//         name : 'ClickTrack',
//         defaultOptions (){
//             return {
//                 pageUrl:window.location.href,
//                 clickDesc : '',
//                 pageName:'',
//                 productName:'',
//                 clickValue : ''
//             }
//         }
//     }
// }


let SDK = {
    install(V) {
        // let isP = process.env.NODE_ENV === 'production'
        V.prototype.$SDK = {
            set(options) {
                mixpanel.people.set(options)
            },
            identify(id){
                mixpanel.identify(id)
            },
            track({pageName,productName}){
                mixpanel.track('pageView',{
                    pageUrl:window.location.href,
                    pageName,
                    productName
                })
                // V.prototype.$matomo && V.prototype.$matomo.setCustomUrl(window.location.href)
                // V.prototype.$matomo && V.prototype.$matomo.trackPageView(pageName)
            }
        }
    }
}

Vue.use(SDK)



