<template>
    <div v-cloak>
        <v-app class="main-page" v-if="hasLogin" style="background-color: #f7f7f7;">
            <v-navigation-drawer fixed v-model="drawerLeft" left clipped disable-resize-watcher app>
                <v-list dense>
                    <v-list-tile v-for="(item, i ) in menus[0]" :key="i" :to="item.path">
                        <v-list-tile-action>
                            <v-icon>{{item.icon}}</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                            <v-list-tile-title>{{item.text}}</v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                </v-list>
            </v-navigation-drawer>
            <v-navigation-drawer fixed v-model="drawerRight" right clipped disable-resize-watcher app>
                <v-list dense>
                    <template v-for="(group, k) in operations">
                        <template v-for="(item , i) in group">
                            <v-divider v-if="item.divider" :key="i"></v-divider>
                            <v-list-tile v-else-if="item.heading && item.path" :key="i+item.heading" :to="item.path">
                                <v-list-tile-action style="min-width: 36px;">
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>
                                        {{ item.heading }}
                                    </v-list-tile-title>
                                </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile v-else-if="!item.heading" :key="i+item.text" :to="item.path">
                                <v-list-tile-action style="min-width: 36px;">
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>
                                        {{ item.text }}
                                    </v-list-tile-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </template>
                        <v-divider v-if="k<operations.length-1" :key="k"></v-divider>
                    </template>
                </v-list>
            </v-navigation-drawer>
            <v-toolbar dark class="client-toolbar" fixed height="48" app tabs clipped-left clipped-right>
                <v-toolbar-side-icon class="hidden-md-and-up"
                                     @click.stop="drawerLeft = !drawerLeft"></v-toolbar-side-icon>
                <a :href="logoHref" target="_blank" style="color: #fff;text-decoration: none;">
                    <v-toolbar-title title="Hosted Open-Source Applications on Kubernetes Cloud Platform">
                        <img class="img-logo" :src="logo"/>
                    </v-toolbar-title>
                </a>
                <v-toolbar-items class="hidden-sm-and-down">
                    <v-btn class="toolbar-btn" v-for="item in categories" flat :to="item.path" :title="item.title" :key="item.text">{{item.text}}</v-btn>
                </v-toolbar-items>
                <v-spacer></v-spacer>
                <div style="display: flex;" class=" right-btns hidden-sm-and-down">
                    <v-layout>
                        <v-flex>
                            <v-badge class="badge-email" v-model="emailFlag" color="error"  overlap dot>
                                <template v-slot:badge>
                                    <span></span>
                                </template>
                             <v-btn title="emails" to="/emails" icon style="display: flex; align-items: center;height: 36px;">
                                <v-icon>email</v-icon>
                             </v-btn>
                            </v-badge>
<!--                            {icon: 'notifications',text: 'Announcements', path: '/announcements'},-->
                        </v-flex>
                        <template v-for="(func, f) in funcs">
                            <v-flex v-if="func.heading==='Profile'" d-flex style="align-items: center;"
                                    :key="f+func.heading">
                                <v-menu
                                        open-on-hover bottom offset-y
                                        transition="slide-x-transition">
                                    <template v-slot:activator="{ on }">
                                    <span v-on="on"
                                          style="display: flex; align-items: center;height: 36px;">
                                        <v-icon>account_box</v-icon> &nbsp;
                                        {{(clientInfo.first_name &&clientInfo.last_name)?`${clientInfo.first_name} ${clientInfo.last_name}`:clientInfo.email}}
                                    </span>
                                    </template>
                                    <v-list dense class="grey lighten-4">
                                        <template v-for="(item, i) in menus[1]">
                                            <v-divider
                                                    v-if="item.divider"
                                                    :key="i"></v-divider>
                                            <v-list-tile v-else-if="!item.heading" :key="i" :to="item.path">
                                                <v-list-tile-action style="min-width: 36px;">
                                                    <v-icon>{{ item.icon }}</v-icon>
                                                </v-list-tile-action>
                                                <v-list-tile-content>
                                                    <v-list-tile-title>
                                                        {{ item.text }}
                                                    </v-list-tile-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </template>
                                    </v-list>
                                </v-menu>
                            </v-flex>
                        </template>
                    </v-layout>
                </div>
                <v-btn icon @click.stop="drawerRight = !drawerRight" class="hidden-md-and-up">
                    <v-badge
                            overlap
                            right
                            color="red">
                        <!--<template slot="badge">-->
                            <!--<span v-if="badgeNum">{{badgeNum}}</span>-->
                        <!--</template>-->
                        <v-icon>more_vert</v-icon>
                    </v-badge>
                </v-btn>
            </v-toolbar>
            <v-content id="main" >
                <template v-if="ready ">
                    <transition
                            appear
                            appear-active-class="animated slideInDown"
                            enter-active-class="animated slideInDown">
                        <IndexNotice ></IndexNotice>
                    </transition>
                    <transition
                            name="slide"
                            mode="out-in">
                        <router-view></router-view>
                    </transition>
                    <Feedback></Feedback>
                </template>
            </v-content>
            <v-dialog
                    style="box-shadow: none;"
                    :value="$store.state.topLoading"
                    attach="#main"
                    width="80"
                    no-click-animation
                    content-class="main-loading"
                    persistent>
                <v-card style="background: transparent;">
                    <img :src="per"/>
                </v-card>
            </v-dialog>
            <UpdateBillingInfo @hook:mounted="$root.billingCpn = $refs.updatebillinginfo" ref="updatebillinginfo"></UpdateBillingInfo>
        </v-app>
        <Token @login="loginHook"></Token>
    </div>
</template>
<script>

    import logo from './assets/images/logo.png'
    import per from './assets/images/preloader.svg'
    import Token from '@/components/Token.vue'
    import UpdateBillingInfo from '@/components/BillingInfo/UpdateBillingInfo.vue'
    import {mapActions, mapState,mapGetters } from 'vuex'
    import {cloudclustersURL} from "@/module/constant"
    const Feedback = () => import('@/components/feedback/index.vue');
    import IndexNotice from '@/components/IndexNotice.vue'

    export default {
        name: "App",
        components: {
            Token,
            Feedback,
            IndexNotice,
            UpdateBillingInfo
        },
        data() {
            return {
                emailFlag:false,
                ready: false,
                hasLogin: false,
                drawerLeft: false,
                drawerRight: false,
                menus: [
                    [
                        {icon: 'home', text: 'Home', path: '/',title:'Homepage of the control panel'},
                        {icon: 'apps', text: 'My Applications', path: '/applications',title:'View all applications'},
                        {icon: 'payment', text: 'Billing', path: '/billing',title:'Manage billing accounts'},
                        {icon: 'receipt', text: 'Tickets', path: '/ticket',title:'Get support from Cloud Clusters'},
                        {icon: 'receipt', text: 'Ordering', path: '/ordering',title:'Order a new application'},
                        {icon: 'receipt',text: 'Documentation', path: '/document',title:'Get help from our knowledge base system'},
                    ], [
                        {heading: 'Profile', icon: 'person', to: '/profile'},
                        {icon: 'panorama_fish_eye', text: 'Account Information', path: '/profile/account'},
                        {icon: 'keyboard', text: 'Change Password', path: '/profile/password'},
                        {divider: true},
                        {icon: 'exit_to_app', text: 'Log Out', path: '/logout'}
                    ],
                ]
            }
        },
        computed: {
            logoHref(){
                return cloudclustersURL
            },
            categories() {
                return this.menus[0]
            },
            operations() {
                return this.menus.slice(1)
            },
            funcs() {
                return this.operations.map(o => o[0])
            },
            ...mapState(['haveOrder']),
            ...mapState('client', ['clientInfo']),
            ...mapGetters('client', [ 'checkInfoCompleted'])
        },
        methods: {
            getEmail() {
                this.$http.get('/emails/', {
                    params : {page_size:20}
                }).then(({assist_data}) => {
                    this.emailFlag = assist_data.unread > 0
                }).catch(e => {
                    this.$message.error(e.detail)
                })
            },
            loginHook(flag) {
                if(flag){
                    this.hasLogin = flag
                    this.getClientInfo().then(() => {
                        this.$nextTick(() => {
                            this.$SDK.set({
                                name : 'Customer',
                                country : this.clientInfo.country
                            })
                            this.$SDK.identify(this.clientInfo.id )
                        })
                        this.$store.commit('toggleLoading',false)
                        this.ready = true
                    })
                    this.getEmail()
                }
            },
            ...mapActions('client', ['getClientInfo'])
        },
        created() {
            this.logo = logo;
            this.per = per;
            document.getElementById('initLoading').style.display = 'none'
            //Read session when page is initialized
            const store = JSON.parse(sessionStorage.getItem('store'))
            if(store&&store.haveOrder) {
                this.$store.commit('setState',{payload:{haveOrder:store.haveOrder}})
            }
            //Cache check status when unload
            window.addEventListener('unload', ()=>{
                sessionStorage.setItem('store',JSON.stringify({haveOrder:this.haveOrder}))
            })
        },
        mounted() {
            this.$root.$on('closeEmailFlag',(flag) => {
                this.emailFlag = flag
            })
        }
    }
</script>

<style lang="scss">
    .main-page {
        .main-loading {
            box-shadow: none;
        }
        .client-toolbar {
            .img-logo {
                &:hover {
                    filter:drop-shadow(2px 2px 2px black);
                }
            }
            .toolbar-btn {
                text-transform: none !important;
            }
            background: linear-gradient(90deg, #3F51B5 0%, #5862B0 50%, #818AA8 80%, #B19DA0 100%);
            height: 48px;
            .v-toolbar__items .v-btn--active {
                color: #e6ce8d;
                background-color: rgba(0, 0, 0, 0.05);
                &:before {
                    background: transparent;
                }
            }
            .v-toolbar__title {
                position: relative;
                min-width: 200px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;
                img {
                    height: 60px;
                }
                span {
                    font-size: 15px;
                    font-weight: normal;
                    margin-left: 5px;
                }
                &:before {
                    position: absolute;
                    content: "";
                    display: inline-block;
                    height: 48px;
                    width: 40px;
                    right: 0;
                    background: url('./assets/images/divider.png');
                    background-size: 70% 100%;
                    background-repeat: no-repeat;
                }
            }
            .right-btns .v-btn--active {
                color: #e6ce8d;
            }
            .badge-email .v-badge__badge{
                height: 8px;
                width: 8px;
                top: 10px;
                right: 8px;
            }
        }
    }
</style>
