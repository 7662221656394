export default {
    strict: true,
    namespaced: true,
    state: {
        token: '',
        clientId : ''
    },
    mutations: {
        setOAuth(state, payload) {
            let keys = Object.keys(payload)
            keys.map(k => state[k] = payload[k])
        }
    },
    actions: {}
}
