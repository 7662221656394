import axios from 'axios'
import cookie from '@/module/utils/cookie'
import {login} from '@/module/service/oAuth'
import Vue from 'vue'

let $http = axios.create({
    baseURL : '/v1',
    timeout : 50000
});
$http.interceptors.request.use(function (config) {
    // Do something before request is sent
    let token = cookie.get('token')
    if(token){
        config.headers['Authorization'] = `Token ${token}`
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
$http.interceptors.response.use(function (response) {
    // Do something with response data
    let data = response.data
    let code  = data.code
    if( code === 0 ){
        return response.data.data
    }else{
        let detail = response.data.detail
        if(typeof detail !== 'string'){
            response.data.detail = 'Request failed '
        }
        return Promise.reject(response)
    }
}, function (error) {
    let response = error.response
    let code = response.status
    if(code === 401) {
        login()
        return Promise.resolve()
    }
    if(code === 403) {
        Vue.prototype.$message.error(error.response.data.detail)
        return Promise.reject()
    }
    let detail = error.response.data.detail
    if(typeof detail !== 'string'){
        error.response.data.detail = 'Request failed '
    }
    // Do something with response error
    return Promise.reject(error.response.data);
});


export default $http
