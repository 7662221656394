import Vue from 'vue'
import Router from 'vue-router'
import {logout} from '@/module/service/oAuth'

Vue.use(Router)

const router = new Router({
    mode : 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: resolve => require(['./views/Home.vue'], resolve)
        },
        {
            path: '/document',
            name: 'Document',
            component: resolve => require(['./views/document/Document.vue'], resolve)
        },
        {
            path: '/ordering',
            name: 'Ordering',
            component: resolve => require(['./views/Ordering/index.vue'], resolve)
        },
        // {
        //     path: '/announcements/:announcementId',
        //     name: 'Announcement Detail',
        //     component: resolve => require(['./views/announcements/Detail.vue'], resolve),
        // },
        {
            path: '/emails',
            name: 'Email Announcement',
            component: resolve => require(['./views/emails/index.vue'], resolve),
        },
        {
            path: '/applications',
            name: 'Applications',
            component: resolve => require(['./views/applications/Applications.vue'], resolve),
        },
        {
            path: '/ticket',
            name: 'ticket',
            component: resolve => require(['./views/tickets/TicketList.vue'], resolve),
        },
        {
            path: '/ticket/:tid',
            name: 'ticket-details',
            component: resolve => require(['./views/tickets/TicketDetails.vue'], resolve),
        },
        {
            path: '/applications/:appId',
            name: 'Application Detail ',
            redirect: '/applications/:appId/overview',
            component: resolve => require(['./views/applications/ApplicationDetail.vue'], resolve),
            children: [
                {
                    path: '/applications/:appId/overview',
                    name: 'Overview',
                    component: resolve => require(['./views/applications/overview/index.vue'], resolve),
                },
                {
                    path: '/applications/:appId/file-manager',
                    name: 'FileManager',
                    component: resolve => require(['./views/applications/filemanager/index.vue'], resolve),
                },
                {
                    path: '/applications/:appId/web-shell',
                    name: 'WebShell',
                    component: resolve => require(['./views/applications/shell/index.vue'], resolve),
                },
                {
                    path: '/applications/:appId/ftp-server',
                    name: 'ftp',
                    component: resolve => require(['./views/applications/ftp/FtpServer.vue'], resolve),
                },
                {
                    path: '/applications/:appId/php-admin',
                    name: 'phpAdmin',
                    component: resolve => require(['./views/applications/phpAdmin/index.vue'], resolve),
                },
                {
                    path: '/applications/:appId/kibana',
                    name: 'KBanner',
                    component: resolve => require(['./views/applications/kibana/index.vue'], resolve),
                },
				// {
				// 	path: '/applications/:appId/db-manager',
				// 	name: 'dbManager',
				// 	component: resolve => require(['./views/applications/dbManager/index.vue'], resolve),
				// },
                {
                    path: '/applications/:appId/guidance',
                    name: 'guidance',
                    component: resolve => require(['./views/applications/guidance/index.vue'], resolve),
                },
                {
                    path: '/applications/:appId/domainSSL',
                    name: 'DomainSSL',
                    component: resolve => require(['./views/applications/domainSSL/DomainSSL.vue'], resolve),
                },
                {
                    path: '/applications/:appId/dbuser',
                    name: 'Databases & Users',
                    component: resolve => require(['./views/applications/dbUser/index.vue'], resolve),
                },
                {
                    path: '/applications/:appId/activities',
                    name: 'Activities',
                    component: resolve => require(['./views/applications/Activities.vue'], resolve),
                },
                {
                    path: '/applications/:appId/events',
                    name: 'Events',
                    component: resolve => require(['./views/applications/Activities.vue'], resolve),
                },
                {
                    path: '/applications/:appId/snapshots',
                    name: 'Snapshots',
                    component: resolve => require(['./views/applications/Snapshots.vue'], resolve),
                },
                {
                    path: '/applications/:appId/metrics',
                    name: 'Metrics',
                    component: resolve => require(['./views/applications/metrics/index.vue'], resolve),
                },
                {
                    path: '/applications/:appId/security',
                    name: 'Security',
                    component: resolve => require(['./views/applications/security/index.vue'], resolve),
                }
            ]
        },
        {
            path: '/billing',
            name: 'Billing',
            redirect: '/billing/billing-info',
            component: resolve => require(['./views/billing/BillingDetail.vue'], resolve),
            children: [
                // {
                //     path: '/billing/account',
                //     name: 'Account Information',
                //     redirect:'/billing/account/profile',
                //     component: resolve => require(['./views/billing/account/Account.vue'], resolve),
                //     children: [
                //         {
                //             path: '/billing/account/profile',
                //             name: 'Profile',
                //             component: resolve => require(['./views/billing/account/Profile.vue'], resolve),
                //         },
                //         {
                //             path: '/billing/account/credentials',
                //             name: 'Credentials',
                //             component: resolve => require(['./views/billing/account/Credentials.vue'], resolve),
                //         }
                //     ]
                // },
                {
                    path: '/billing/paymentmethod',
                    name: 'Payment Method',
                    redirect: '/billing/paymentmethod/credit',
                    component: resolve => require(['./views/billing/payment/PaymentMethod.vue'], resolve),
                    children: [
                        {
                            path: '/billing/paymentmethod/credit',
                            name: 'Credit Cards',
                            component: resolve => require(['./views/billing/payment/Credit.vue'], resolve),
                        },
                        {
                            path: '/billing/paymentmethod/paypal',
                            name: 'Paypal',
                            component: resolve => require(['./views/billing/payment/Paypal.vue'], resolve),
                        },
                    ]
                },
                {
                    path: '/billing/invoices',
                    name: 'Invoices',
                    component: resolve => require(['./views/billing/invoices/Invoices.vue'], resolve),
                },
                {
                    path: '/billing/billing-info',
                    name: 'BillingInfo',
                    component: resolve => require(['./views/billing/billingInfo/index.vue'], resolve),
                },
				{
					path: '/billing/credits',
					name: 'Credits',
					component: resolve => require(['./views/billing/credits/Credits.vue'], resolve),
				}
            ]
        },
        {
            path: '/profile',
            name: 'Profile',
            component: resolve => require(['./views/profile/index.vue'], resolve),
            children: [
                {
                    path: '/profile/account',
                    name: 'Account Information',
                    component: resolve => require(['./views/profile/Profile.vue'], resolve),
                },
                // {
                //     path: '/profile/contact',
                //     name: 'Contact Information',
                //     component: resolve => require(['./views/profile/Contact.vue'], resolve),
                // },
                // {
                //     path: '/profile/social',
                //     name: 'Social Information',
                //     component: resolve => require(['./views/profile/Social.vue'], resolve),
                // },
                {
                    path: '/profile/password',
                    name: 'Change Password',
                    component: resolve => require(['./views/profile/Password.vue'], resolve),
                },
            ]
        },
        {
            path: '/check/:product/:plan_uuid',
            name: 'Check',
            component: resolve => require(['./views/check/index.vue'], resolve)
        },
        {
            path: '/processing',
            name: 'processing',
            component: resolve => require(['./views/check/processing.vue'], resolve)
        },
        {
            path: '/unverified',
            name: 'processing',
            component: resolve => require(['./views/check/Unverified.vue'], resolve)
        },
        {
            path: '/wait-verify',
            name: 'waitVerify',
            component: resolve => require(['./views/check/WaitVerify.vue'], resolve)
        },
        {
            path: '/invoice-detail/:invoiceId',
            name: 'InvoiceDetail',
            component: resolve => require(['./views/invoice/InvoiceDetail.vue'], resolve)
        },
        {
            path: '/payment/:invoiceId',
            name: 'payment',
            component: resolve => require(['./views/payment/payment.vue'], resolve)
        }
    ]
})

router.beforeEach((to, from, next) => {
    to.path === '/logout' ? logout() : next()
})


export default router
