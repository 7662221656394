import $http from '@/module/http'
import {products} from "@/module/constant";
import Vue from 'vue'

let pList = null;
let pDatabase = null;
let pDBUsers = null;
let pConnection = null;

export default {
    strict: true,
    namespaced: true,
    state: {
        applicationList: [],
        appInfo: {},
        deploymentInfo: {},
        stats: {},
        dataBases: [],
        showGuidance:false,
        databaseDetail:{},
        users: []
    },
    getters: {
        billingMonth() {
            return (duration) => {
                switch (duration) {
                    case 1: return 'month'
                    case 3: return '3 months'
                    case 6: return '6 months'
                    case 12: return 'year'
                    case 24: return '2 years'
                }
            }
        },
        getPackageStatus() {
            return (item)=> {
                    if (item.status==='Cancelled') {
                        return 'Cancelled'
                    } else if (['Failure', 'Timeout','Undeployed'].indexOf(item.deploy_status) !== -1) {
                        return 'Deploy Failed'
                    } else if(['Deploying', 'Deleting'].indexOf(item.deploy_status) !== -1) {
                        return 'process'
                    } else if (item.status === 'Active' && item.deploy_status === 'Success') {
                        return 'Active'
                    } else {
                        return item.status
                    }
                }
        },
        getProductLogo() {
            return (name) => {
                const list = Object.keys(products)
                return products[list.find(o => o === name.toLowerCase())].logo
            }
        },
        // getGuidanceSteps(state) {
        //         const Obj = {
        //             Database: [
        //                 {
        //                     element: '#db-user',
        //                     popover: {
        //                         title: `How to Create ${state.appInfo.product_name==='Cassandra'?'Keyspace':'Databases'} and Users`,
        //                         description: `Click it to visit the ${state.appInfo.product_name==='Cassandra'?'Keyspace':'database'} and user list.`,
        //                     }
        //                 },
        //                 {
        //                     element: '#create-db',
        //                     popover: {
        //                         title: `How to Create ${state.appInfo.product_name==='Cassandra'?'Keyspace':'Databases'} and Users`,
        //                         description: `Click it to create a ${state.appInfo.product_name==='Cassandra'?'keyspace':'database'}.`,
        //                     },
        //                 },
        //                 {
        //                     element: '#create-user',
        //                     popover: {
        //                         title: `How to Create ${state.appInfo.product_name==='Cassandra'?'Keyspace':'Databases'} and Users`,
        //                         description: 'Click it to create a user',
        //                     }
        //                 }
        //             ],
        //             'Message Queue':[
        //                 {
        //                     element: '#db-user',
        //                     popover: {
        //                         title: 'How to Create Topics and Users',
        //                         description: 'Click it to visit the topic and user list.',
        //                     }
        //                 },
        //                 {
        //                     element: '#create-db',
        //                     popover: {
        //                         title: 'How to Create Topics and Users',
        //                         description: 'Click it to create a topic.',
        //                     },
        //                 },
        //                 {
        //                     element: '#create-user',
        //                     popover: {
        //                         title: 'How to Create Topics and Users',
        //                         description: 'Click it to create a user',
        //                     }
        //                 }
        //             ],
        //             Ecom : [
        //                 {
        //                     element: '#site-ssl',
        //                     popover: {
        //                         title: 'How to Add Domains to Your Site',
        //                         description: 'Click it to visit the domain list.',
        //                     }
        //                 },
        //                 {
        //                     element: '#site_idx_0',
        //                     popover: {
        //                         title: 'How to Add Domains to Your Site',
        //                         description: 'Click it to add your domain to your site.',
        //                     },
        //                 }
        //             ],
        //             CMS : [
        //                 {
        //                     element: '#site-ssl',
        //                     popover: {
        //                         title: 'How to Add Domains to Your Site',
        //                         description: 'Click it to visit the domain list.',
        //                     }
        //                 },
        //                 {
        //                     element: '#site_idx_0',
        //                     popover: {
        //                         title: 'How to Add Domains to Your Site',
        //                         description: 'Click it to add your domain to your site.',
        //                     },
        //                 }
        //             ],
        //             Operations : [
        //                 {
        //                     element: '#site-ssl',
        //                     popover: {
        //                         title: 'How to Add Domains to Your Site',
        //                         description: 'Click it to visit the domain list.',
        //                     }
        //                 },
        //                 {
        //                     element: '#site_idx_0',
        //                     popover: {
        //                         title: 'How to Add Domains to Your Site',
        //                         description: 'Click it to add your domain to your site.',
        //                     },
        //                 }
        //             ],
        //             CRM : [
        //                 {
        //                     element: '#site-ssl',
        //                     popover: {
        //                         title: 'How to Add Domains to Your Site',
        //                         description: 'Click it to visit the domain list.',
        //                     }
        //                 },
        //                 {
        //                     element: '#site_idx_0',
        //                     popover: {
        //                         title: 'How to Add Domains to Your Site',
        //                         description: 'Click it to add your domain to your site.',
        //                     },
        //                 }
        //             ],
        //             Education : [
        //                 {
        //                     element: '#site-ssl',
        //                     popover: {
        //                         title: 'How to Add Domains to Your Site',
        //                         description: 'Click it to visit the domain list.',
        //                     }
        //                 },
        //                 {
        //                     element: '#site_idx_0',
        //                     popover: {
        //                         title: 'How to Add Domains to Your Site',
        //                         description: 'Click it to add your domain to your site.',
        //                     },
        //                 }
        //             ]
        //         }
        //         Obj[state.appInfo.category].push({
        //             element: '#guidance',
        //             popover: {
        //                 title: 'Guidance',
        //                 description: `Need help getting started? Click Guidance for more details.`,
        //                 showButtons: false,
        //             }
        //         })
        //         // if (['MySQL','PostgreSQL' ,'Magento','MariaDB','SQL Server','WordPress','Odoo','Moodle','Kafka','Cassandra','Prometheus'].indexOf(state.appInfo.product_name)!== -1) {
        //         // }
        //         return Obj[state.appInfo.category]
        // },
        getProductNotext() {
            return (name) => {
                const list = Object.keys(products)
                return products[list.find(o => o === name.toLowerCase())].logo_notext
            }
        },
        getServiceId() {
            return (appInfo) => {
                if (appInfo.stacks.length === 0 || appInfo.stacks[0].services.length === 0) {
                    return ''
                }
                return appInfo.stacks[0].services[0].uuid
            }
        },
        getService(state) {
            return (idx=0) => {
                if (state.appInfo.stacks.length === 0 || state.appInfo.stacks[0].services.length === 0) {
                    return {}
                }
                return state.appInfo.stacks[0].services[idx]
            }
        },

        getCategoryMenus(state, getters, rootState) {
            return (appInfo) => {
                let showFileManager , showSSH ,showPhpadmin,showGuid,showKibana
                if(appInfo.stacks.length !== 0 && appInfo.stacks[0].services.length !== 0){
                    if(appInfo.stacks[0].services[0].filebrowser_info.has_filebrowser){
                        showFileManager = true
                    }
                    if(appInfo.stacks[0].services[0].phpmyadmin_info.has_phpmyadmin){
                        showPhpadmin = true
                    }
                    if(appInfo.product_name.toLowerCase() === 'elasticsearch'){
                        showKibana = true
                    }
                    if(rootState.client.clientInfo.permissions.web_shell){
                        showSSH = true
                    }
                    if (['mongodb'].indexOf(appInfo.product_name.toLowerCase())===-1) {
                        showGuid = true
                    }
                }
                let arr = [
                    {icon: '', text: 'Overview',  path: `/applications/${appInfo.uuid}/overview`,category: 'ALL' , include : true , title:'General settings and information'},
                    {icon: '', text: `${appInfo.category === 'Message Queue'?'Topic':'DB'} & User`, path: `/applications/${appInfo.uuid}/dbuser`,category : ['Database','Message Queue'] , include : !showKibana,stepId:'db-user', title:`Manage your ${appInfo.category === 'Message Queue'?'topics':'databases'} and users`},
                    {icon: '', text: 'Site & SSL', path: `/applications/${appInfo.uuid}/domainSSL`, category : ['Ecom','CMS','Runtimes','CRM','Education','Operations'] , include : true , stepId :'site-ssl', title:'Manage your websites, DNS, SSL, Domain and URL redirection'},
                    {icon: '', text: 'Security', path: `/applications/${appInfo.uuid}/security`,category: ['Database','Message Queue'] , include : true, title:'Manage your applications’s security setting'},
                    {icon: '', text: 'phpMyAdmin', path: `/applications/${appInfo.uuid}/php-admin`,category: 'ALL' , include : showPhpadmin, title:'Manage MySQL/MariaDB'},
                    {icon: '', text: 'Kibana', path: `/applications/${appInfo.uuid}/kibana`,category: 'ALL' , include : showKibana, title:'Manage Elasticsearch'},
                    {icon: '', text: 'File Manager', path: `/applications/${appInfo.uuid}/file-manager`,category: 'ALL' , include : showFileManager, title:'Manage the application files and folders.'},
                    {icon: '', text: 'Shell/SSH', path: `/applications/${appInfo.uuid}/web-shell`,category: 'ALL' , include : showSSH, title:'Manage your applications via command line interface'},
                    {icon: '', text: 'FTPS Server', path: `/applications/${appInfo.uuid}/ftp-server`,category: 'ALL' , include : true, title:'Encrypt data transfer between your local machine and the application server'},
                    {icon: '', text: 'Metrics', path: `/applications/${appInfo.uuid}/metrics`,category: 'ALL' , include : true, title:'Check your applications’ resource usage'},
                    {icon: '', text: 'Snapshots', path: `/applications/${appInfo.uuid}/snapshots`,category: 'ALL' , include : true, title:'Manage data backups and restorations'},
                    {icon: '', text: 'Events', path: `/applications/${appInfo.uuid}/activities`,category: 'ALL' , include : true, title:'Check the history of application management operations'},
                    {icon: '', text: 'Guidance', path: `/applications/${appInfo.uuid}/guidance`,category : 'ALL' , include : showGuid,stepId:'guidance', title:'Get step-by-step instructions on application management'},
                ]
                return arr.filter((o) => {
                    return ( o.category === "ALL" || o.category.indexOf(appInfo.category) !== -1) && o.include
                })
            }
        }
    },
    mutations: {
        setApplicationList(state, payload) {
            state.applicationList = payload
        },
    },
    actions: {
        getApplicationList: ({rootState, commit}, forceRefresh) => {
            if (!pList || forceRefresh) {
                pList = $http.get('/packages/', {
                    params: {
                        client_id: rootState.client.clientInfo.id,
                        status: 'Active,Pending,Suspended,Cancelled',
                        page_size: 999
                    }
                }).then((res) => {
                    commit('setState', {
                        module: 'application',
                        payload: {
                            applicationList: res.results
                        }
                    }, {root: true})
                    return res.results
                })
            }
            return pList
        },
        getMaskStatus({ state, getters}){
            return $http.get(`/packages/should_mask/`,{
                params: {
                    service_uuid: getters.getServiceId(state.appInfo)
                }
            })
        },
        getAppStats: ({commit, state, getters}, forceRefresh) => {
            if (!state.stats.instance_status || forceRefresh) {
                return $http.get(`/running-status/`, {
                    params: {
                        service_uuid: getters.getServiceId(state.appInfo)
                    }
                }).then((res) => {
                    commit('setState', {
                        module: 'application',
                        payload: {
                            stats: res
                        }
                    }, {root: true})
                    return res
                }).catch((e) => {
                    Vue.prototype.$message.error({
                        content: e.detail,
                        duration: 0,
                        closable: true
                    })
                    return Promise.reject(e)
                })
            } else {
                return Promise.resolve(state.stats)
            }
        },

        getAppDetailById({commit}, {appId, isReset}) {
            if (isReset) {
                pDatabase = null;
                pDBUsers = null;
                pConnection = null;
                commit('setState', {
                    module: 'application',
                    payload: {
                        deploymentInfo: {},
                        stats: {},
                        dataBases: [],
                        databaseDetail:{},
                        users: []
                    }
                }, {root: true})
            }
            return $http.get(`/packages/${appId}/`).then((res) => {
                commit('setState', {
                    module: 'application',
                    payload: {
                        appInfo: res,
                    }
                }, {root: true})
                return res
            })
        },
        getConnectionInfo({commit, state, getters}, forceRefresh) {
            if (!pConnection || forceRefresh) {
                pConnection = $http.get(`/deployment-info/`, {
                    params: {
                        service_uuid: getters.getServiceId(state.appInfo)
                    }
                }).then((info) => {
                    commit('setState', {
                        module: 'application',
                        payload: {
                            deploymentInfo: info
                        }
                    }, {root: true})
                    return info
                }).catch((e) => {
                    Vue.prototype.$message.error('Oops, it failed to get connection information, please contact us at support@cloudclusters.io for this issue.')
                    return Promise.reject(e.detail)
                })
            }
            return pConnection
        },
        getDataBaseList({commit, state, getters}, forceRefresh) {
            if (!pDatabase || forceRefresh) {
                pDatabase = $http.get(`/databases/`, {
                    params: {
                        service_uuid: getters.getServiceId(state.appInfo),
                        page_size:999
                    }
                }).then((res) => {
                    commit('setState', {
                        module: 'application',
                        payload: {
                            dataBases: res.results
                        }
                    }, {root: true})
                    return res
                }).catch((e) => {
                    Vue.prototype.$message.error({
                        // content: 'Oops, it failed to get database list, please refresh this page later or contact us at support@cloudclusters.io for this issue.',
                        content: e.detail,
                        duration: 0,
                        closable: true
                    })
                    return Promise.reject(e)
                })
            }
            return pDatabase
        },

        getDataBaseUserList({commit, state, getters}, forceRefresh) {
            if (!pDBUsers || forceRefresh) {
                pDBUsers = $http.get(`/db-users/`, {
                    params: {
                        service_uuid: getters.getServiceId(state.appInfo),
                    }
                }).then((res) => {
                    commit('setState', {
                        module: 'application',
                        payload: {
                            users: res.results
                        }
                    }, {root: true})
                    return res
                }).catch((e) => {
                    // Vue.prototype.$message.error('Oops, it failed to get database user list, please refresh this page later or contact us at support@cloudclusters.io for this issue.')
                    Vue.prototype.$message.error(e.detail)
                    return Promise.reject(e)
                })
            }
            return pDBUsers
        },

        getDataBaseDetail({commit}, uuid) {
            return new Promise((resolve, reject) => {
                $http.get(`/databases/${uuid}/`).then((res) => {
                    commit('setState', {
                        module: 'application',
                        payload: {
                            databaseDetail: res,
                        }
                    }, {root: true})
                    resolve(res)
                }).catch((e) => {
                    // Vue.prototype.$message.error('Oops, something bad happened while retrieving database information, please contact us at support@cloudclusters.io for this issue.')
                    Vue.prototype.$message.error(e.detail)
                    reject(e)
                })
            })
        },
        getCurrentAddons({state}) {
            return $http.get(`/packages/${state.appInfo.uuid}/already_addons_amount/`)
        }
    }
}
