<template>
    <v-form ref="form">
        <v-layout row wrap>
            <v-flex xs12 sm6 md3 lg3 pa-3>
                <v-text-field
                        v-model="formData.first_name"
                        :rules="[rules.required]"
                        clearable>
                    <template v-slot:label>
                        <RequiredDot>First Name</RequiredDot>
                    </template>
                </v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 pa-3>
                <v-text-field
                        v-model="formData.last_name"
                        :rules="[rules.required]"
                        clearable>
                    <template v-slot:label>
                        <RequiredDot>Last Name</RequiredDot>
                    </template>
                </v-text-field>
            </v-flex>
            <v-flex xs12 sm6 pa-3>
                <v-autocomplete
                        v-model="formData.country"
                        :items="countryList"
                        @change="getStates"
                        item-text="name"
                        :rules="[rules.required]"
                        hide-details
                        hide-no-data>
                    <template v-slot:label>
                        <RequiredDot>Country / Region</RequiredDot>
                    </template>
                </v-autocomplete>
            </v-flex>
            <v-flex xs12 sm6 pa-3>
                <v-autocomplete
                        :disabled="disabledState || this.stateList.length===0"
                        v-model="formData.state"
                        :items="stateList"
                        item-text="name"
                        :rules="[rules.required]"
                        hide-details
                        hide-no-data>
                    <template v-slot:label>
                        <RequiredDot>State</RequiredDot>
                    </template>
                </v-autocomplete>
            </v-flex>
            <v-flex xs12 sm6 pa-3>
                <v-text-field
                        v-model="formData.city"
                        :rules="[rules.required]"
                        clearable>
                    <template v-slot:label>
                        <RequiredDot>City</RequiredDot>
                    </template>
                </v-text-field>
            </v-flex>
            <v-flex xs12 sm6 pa-3>
                <v-text-field
                        v-model="formData.postcode"
                        :rules="[rules.required]"
                        clearable>
                    <template v-slot:label>
                        <RequiredDot>Postcode</RequiredDot>
                    </template>
                </v-text-field>
            </v-flex>
            <!--            <v-flex xs12 sm6 pa-3>-->
            <!--                <v-text-field-->
            <!--                        v-model="formData.company"-->
            <!--                        label="Company"-->
            <!--                        clearable-->
            <!--                ></v-text-field>-->
            <!--            </v-flex>-->
            <v-flex xs12 sm6 pa-3>
                <v-text-field
                        v-model="formData.phone_number"
                        :rules="[rules.required]"
                        clearable>
                    <template v-slot:label>
                        <RequiredDot>Phone Number</RequiredDot>
                    </template>
                </v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6 pa-3>
                <v-text-field
                        v-model="formData.address1"
                        :rules="[rules.required]"
                        clearable>
                    <template v-slot:label>
                        <RequiredDot>Address</RequiredDot>
                    </template>
                </v-text-field>
            </v-flex>
            <v-flex xs12 sm6 pa-3>
                <v-text-field
                        v-model="formData.company"
                        label="Company"
                        clearable
                ></v-text-field>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
    import {mapState, mapActions, mapMutations} from 'vuex'
    import rules from '@/module/rules/index.js'
    import RequiredDot from '@/components/RequiredDot.vue'


    export default {
        name: "BillingInfoForm",
        data() {
            return {
                formData: {},
                countryList: [],
                stateList: [],
                disabledState: false,
                rules
            }
        },
        components:{RequiredDot},
        computed:{
            ...mapState('client', ['clientInfo'])
        },
        methods:{
            getStates(countryName) {
                let country = this.countryList.find(o => o.name === countryName)
                if (!country) {
                    this.stateList = []
                } else {
                    this.disabledState = true
                    this.$http.get('/states/', {
                        params: {
                            country: country.id,
                            page_size: 300
                        }
                    }).then(res => {
                        this.disabledState = false
                        this.stateList = res.results
                    }).catch(() => {
                        this.disabledState = false
                    })
                }
            },
            submit() {
                if (!this.$refs.form.validate()) {
                    return Promise.reject()
                }
                return  this.$http({
                    method: 'put',
                    url: `/clients/${this.clientInfo.id}/`,
                    data: this.formData
                }).then((res) => {
                    this.setClientInfo(res)
                    this.$emit('submit',res)
                    return Promise.resolve(res)
                }).catch((e) => {
                    this.$message.error(e.detail)
                    return Promise.reject(e)
                });
            },
            $validate() {
                return this.$refs.form.validate()
            },
            $reset() {
                this.$refs.form.reset()
            },
            init() {
                this.formData = Object.assign({},this.clientInfo)
            },
            ...mapActions('client', ['getCountries']),
            ...mapMutations('client',['setClientInfo'])
        },
        created() {
            this.init()
            this.getCountries().then(res => {
                this.countryList = res
                if (this.clientInfo.country) {
                    this.getStates(this.clientInfo.country)
                }
            })
        }
    }
</script>

<style lang="scss">

</style>