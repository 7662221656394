var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm._t("default"),
      _c(
        "i",
        {
          staticClass: "required-dot",
          style: { color: _vm.$vuetify.theme.error }
        },
        [_vm._v("*")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }