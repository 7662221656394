import Vue from 'vue'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
let plugin = {
    install(Vue) {
        Vue.prototype.$vuetify = Object.assign(Vue.prototype.$vuetify,{
            iconfont: 'md',
            theme: {
                primary: '#3F51B5',
                secondary: '#FFC107',
                accent: '#607D8B',
                error: '#FF5252',
                info: '#CFD8DC',
                success: '#10C610',
                warning: '#FFC107'
            }
        })
    }
}

Vue.use(plugin)
