<template>
    <span>
        <slot></slot><i class="required-dot" :style="{'color':$vuetify.theme.error}">*</i>
    </span>
</template>

<script>
    export default {
        name: "RequiredDot"
    }
</script>

<style lang="scss">
    .required-dot{
        display: inline-block;
        width: 10px;
    }
</style>