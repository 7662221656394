<template>
    <!--    Priority is given to unconfirmed order alerts, followed by incomplete information alerts.-->
    <div class="index-notice" :class="{'active': !notShowNoticePage&&(haveOrder||accountShow)}">
        <div class="notice-wrapper">
            <v-layout v-if="haveOrder" justify-center>
                <v-flex d-flex shrink align-center style="color: rgb(64,157,226);line-height: 40px;overflow: hidden;">
                    <div style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                        <v-icon color="primary lighten-1" style="position: relative;top: 2px;">info</v-icon>
                        Here, you have an unconfirmed order.
                    </div>
                </v-flex>
                <v-flex shrink>
                    <v-btn class="text-none" small color="rgb(64,157,226)" dark @click="$router.push(haveOrder)" >Confirm Now</v-btn>
                </v-flex>
            </v-layout>
            <span v-if="!haveOrder&& accountShow" style="color: rgb(64,157,226)">
                <v-icon color="primary lighten-1" style="position: relative;top: 2px;">info</v-icon>
                Please
                <router-link to="/billing/billing-info" style="color: rgb(64,157,226)">
                    complete billing information
                </router-link>
                to activate your paid account.
            </span>
            <v-btn style="position: absolute;right: 0;top: 5px;" v-if="!notShowNoticePage&&(haveOrder||accountShow)" icon small @click="clear">
                <v-icon color="accent"  >close</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>


    import {mapState,mapGetters} from 'vuex'
    export default {
        name: "IndexNotice",
        data() {
            return {
                unpaid: 0,
                notShowNoticePage: false,
                showAcc:true
            }
        },
        computed: {
            accountShow() {
                return  !this.checkInfoCompleted() && this.showAcc
            },
            ...mapState(['haveOrder']),
            ...mapGetters('client' , ['checkInfoCompleted']),
        },
        methods: {
            judgePage(path) {
                if (path.search('check') !== -1 || path.search('invoice-detail') !== -1  || path.search('ordering') !== -1  ||path === '/billing/billing-info') {
                    this.notShowNoticePage = true
                } else {
                    this.notShowNoticePage = false
                }
            },
            clear() {
                this.$store.commit('setState',{
                    payload:{
                        haveOrder:""
                    }
                })
                this.showAcc = false
            }
        },
        watch: {
            $route(){
                this.judgePage(this.$route.path)
            }
        },
        created() {
            this.judgePage(this.$route.path)
        }
    }
</script>

<style scoped lang="scss">
    .index-notice {
        height: 0;
        position: relative;
        transition: height ease 0.3s;
        &.active{
            height: 50px;
            .notice-wrapper{
                opacity: 1;
            }
        }
        .notice-wrapper {
            position: absolute;
            transition: opacity ease 0.3s;
            opacity: 0;
            bottom: 0;
            height: 50px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(231,241,255);
            span {
                margin-left: 5px;
                i {
                    font-style: normal;
                }
            }
        }
        .icon-button-close {
            display: inline-block;
            position: absolute;
            right: 20px;
            bottom: 8px;
            cursor: pointer;
        }
        .icon-button-open {
            display: inline-block;
            transition: opacity ease 0.3s;
            position: absolute;
            right: 20px;
            bottom: -40px;
            cursor: pointer;
        }
    }

</style>
