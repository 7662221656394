var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": 800,
        "no-click-animation": "",
        lazy: "",
        scrollable: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "mx-2",
              staticStyle: { "justify-content": "center" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "font-weight-regular",
                  staticStyle: { "font-size": "24px" }
                },
                [
                  _vm._v(
                    "\n                Completing Billing Information\n            "
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mt-2 font-weight-regular",
                  staticStyle: { "text-align": "center", "font-size": "16px" }
                },
                [
                  _vm._v(
                    "\n                This is a paying order as you have run out of trial packages. Please submit the billing information to activate your paid account.\n            "
                  )
                ]
              )
            ]
          ),
          _c(
            "v-card-text",
            [
              _vm.dialog
                ? _c("billing-info-form", { ref: "billingform" })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "mx-3 mb-3" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text-none",
                  attrs: {
                    large: "",
                    outline: "",
                    color: "blue darken-1",
                    flat: ""
                  },
                  on: { click: _vm.close }
                },
                [_vm._v("Cancel")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text-none",
                  attrs: { large: "", color: "primary", loading: _vm.loading },
                  on: { click: _vm.updateBillingInfo }
                },
                [_vm._v("Submit")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }