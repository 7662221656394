var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.hasLogin
        ? _c(
            "v-app",
            {
              staticClass: "main-page",
              staticStyle: { "background-color": "#f7f7f7" }
            },
            [
              _c(
                "v-navigation-drawer",
                {
                  attrs: {
                    fixed: "",
                    left: "",
                    clipped: "",
                    "disable-resize-watcher": "",
                    app: ""
                  },
                  model: {
                    value: _vm.drawerLeft,
                    callback: function($$v) {
                      _vm.drawerLeft = $$v
                    },
                    expression: "drawerLeft"
                  }
                },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    _vm._l(_vm.menus[0], function(item, i) {
                      return _c(
                        "v-list-tile",
                        { key: i, attrs: { to: item.path } },
                        [
                          _c(
                            "v-list-tile-action",
                            [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                            1
                          ),
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-title", [
                                _vm._v(_vm._s(item.text))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "v-navigation-drawer",
                {
                  attrs: {
                    fixed: "",
                    right: "",
                    clipped: "",
                    "disable-resize-watcher": "",
                    app: ""
                  },
                  model: {
                    value: _vm.drawerRight,
                    callback: function($$v) {
                      _vm.drawerRight = $$v
                    },
                    expression: "drawerRight"
                  }
                },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _vm._l(_vm.operations, function(group, k) {
                        return [
                          _vm._l(group, function(item, i) {
                            return [
                              item.divider
                                ? _c("v-divider", { key: i })
                                : item.heading && item.path
                                ? _c(
                                    "v-list-tile",
                                    {
                                      key: i + item.heading,
                                      attrs: { to: item.path }
                                    },
                                    [
                                      _c(
                                        "v-list-tile-action",
                                        {
                                          staticStyle: { "min-width": "36px" }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(item.icon))
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(item.heading) +
                                                "\n                                    "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : !item.heading
                                ? _c(
                                    "v-list-tile",
                                    {
                                      key: i + item.text,
                                      attrs: { to: item.path }
                                    },
                                    [
                                      _c(
                                        "v-list-tile-action",
                                        {
                                          staticStyle: { "min-width": "36px" }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(item.icon))
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(item.text) +
                                                "\n                                    "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }),
                          k < _vm.operations.length - 1
                            ? _c("v-divider", { key: k })
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-toolbar",
                {
                  staticClass: "client-toolbar",
                  attrs: {
                    dark: "",
                    fixed: "",
                    height: "48",
                    app: "",
                    tabs: "",
                    "clipped-left": "",
                    "clipped-right": ""
                  }
                },
                [
                  _c("v-toolbar-side-icon", {
                    staticClass: "hidden-md-and-up",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.drawerLeft = !_vm.drawerLeft
                      }
                    }
                  }),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "#fff", "text-decoration": "none" },
                      attrs: { href: _vm.logoHref, target: "_blank" }
                    },
                    [
                      _c(
                        "v-toolbar-title",
                        {
                          attrs: {
                            title:
                              "Hosted Open-Source Applications on Kubernetes Cloud Platform"
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "img-logo",
                            attrs: { src: _vm.logo }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-toolbar-items",
                    { staticClass: "hidden-sm-and-down" },
                    _vm._l(_vm.categories, function(item) {
                      return _c(
                        "v-btn",
                        {
                          key: item.text,
                          staticClass: "toolbar-btn",
                          attrs: { flat: "", to: item.path, title: item.title }
                        },
                        [_vm._v(_vm._s(item.text))]
                      )
                    }),
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "div",
                    {
                      staticClass: " right-btns hidden-sm-and-down",
                      staticStyle: { display: "flex" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c(
                            "v-flex",
                            [
                              _c(
                                "v-badge",
                                {
                                  staticClass: "badge-email",
                                  attrs: {
                                    color: "error",
                                    overlap: "",
                                    dot: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "badge",
                                        fn: function() {
                                          return [_c("span")]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1336543777
                                  ),
                                  model: {
                                    value: _vm.emailFlag,
                                    callback: function($$v) {
                                      _vm.emailFlag = $$v
                                    },
                                    expression: "emailFlag"
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        height: "36px"
                                      },
                                      attrs: {
                                        title: "emails",
                                        to: "/emails",
                                        icon: ""
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("email")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.funcs, function(func, f) {
                            return [
                              func.heading === "Profile"
                                ? _c(
                                    "v-flex",
                                    {
                                      key: f + func.heading,
                                      staticStyle: { "align-items": "center" },
                                      attrs: { "d-flex": "" }
                                    },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "open-on-hover": "",
                                            bottom: "",
                                            "offset-y": "",
                                            transition: "slide-x-transition"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center",
                                                            height: "36px"
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("account_box")
                                                        ]),
                                                        _vm._v(
                                                          "  \n                                        " +
                                                            _vm._s(
                                                              _vm.clientInfo
                                                                .first_name &&
                                                                _vm.clientInfo
                                                                  .last_name
                                                                ? _vm.clientInfo
                                                                    .first_name +
                                                                    " " +
                                                                    _vm
                                                                      .clientInfo
                                                                      .last_name
                                                                : _vm.clientInfo
                                                                    .email
                                                            ) +
                                                            "\n                                    "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            {
                                              staticClass: "grey lighten-4",
                                              attrs: { dense: "" }
                                            },
                                            [
                                              _vm._l(_vm.menus[1], function(
                                                item,
                                                i
                                              ) {
                                                return [
                                                  item.divider
                                                    ? _c("v-divider", {
                                                        key: i
                                                      })
                                                    : !item.heading
                                                    ? _c(
                                                        "v-list-tile",
                                                        {
                                                          key: i,
                                                          attrs: {
                                                            to: item.path
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-tile-action",
                                                            {
                                                              staticStyle: {
                                                                "min-width":
                                                                  "36px"
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.icon
                                                                  )
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-tile-content",
                                                            [
                                                              _c(
                                                                "v-list-tile-title",
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                        " +
                                                                      _vm._s(
                                                                        item.text
                                                                      ) +
                                                                      "\n                                                    "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "hidden-md-and-up",
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.drawerRight = !_vm.drawerRight
                        }
                      }
                    },
                    [
                      _c(
                        "v-badge",
                        { attrs: { overlap: "", right: "", color: "red" } },
                        [_c("v-icon", [_vm._v("more_vert")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-content",
                { attrs: { id: "main" } },
                [
                  _vm.ready
                    ? [
                        _c(
                          "transition",
                          {
                            attrs: {
                              appear: "",
                              "appear-active-class": "animated slideInDown",
                              "enter-active-class": "animated slideInDown"
                            }
                          },
                          [_c("IndexNotice")],
                          1
                        ),
                        _c(
                          "transition",
                          { attrs: { name: "slide", mode: "out-in" } },
                          [_c("router-view")],
                          1
                        ),
                        _c("Feedback")
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c(
                "v-dialog",
                {
                  staticStyle: { "box-shadow": "none" },
                  attrs: {
                    value: _vm.$store.state.topLoading,
                    attach: "#main",
                    width: "80",
                    "no-click-animation": "",
                    "content-class": "main-loading",
                    persistent: ""
                  }
                },
                [
                  _c("v-card", { staticStyle: { background: "transparent" } }, [
                    _c("img", { attrs: { src: _vm.per } })
                  ])
                ],
                1
              ),
              _c("UpdateBillingInfo", {
                ref: "updatebillinginfo",
                on: {
                  "hook:mounted": function($event) {
                    _vm.$root.billingCpn = _vm.$refs.updatebillinginfo
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("Token", { on: { login: _vm.loginHook } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }