var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "index-notice",
      class: {
        active: !_vm.notShowNoticePage && (_vm.haveOrder || _vm.accountShow)
      }
    },
    [
      _c(
        "div",
        { staticClass: "notice-wrapper" },
        [
          _vm.haveOrder
            ? _c(
                "v-layout",
                { attrs: { "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticStyle: {
                        color: "rgb(64,157,226)",
                        "line-height": "40px",
                        overflow: "hidden"
                      },
                      attrs: { "d-flex": "", shrink: "", "align-center": "" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "white-space": "nowrap",
                            "text-overflow": "ellipsis",
                            overflow: "hidden"
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticStyle: { position: "relative", top: "2px" },
                              attrs: { color: "primary lighten-1" }
                            },
                            [_vm._v("info")]
                          ),
                          _vm._v(
                            "\n                    Here, you have an unconfirmed order.\n                "
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: {
                            small: "",
                            color: "rgb(64,157,226)",
                            dark: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.$router.push(_vm.haveOrder)
                            }
                          }
                        },
                        [_vm._v("Confirm Now")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.haveOrder && _vm.accountShow
            ? _c(
                "span",
                { staticStyle: { color: "rgb(64,157,226)" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticStyle: { position: "relative", top: "2px" },
                      attrs: { color: "primary lighten-1" }
                    },
                    [_vm._v("info")]
                  ),
                  _vm._v("\n            Please\n            "),
                  _c(
                    "router-link",
                    {
                      staticStyle: { color: "rgb(64,157,226)" },
                      attrs: { to: "/billing/billing-info" }
                    },
                    [
                      _vm._v(
                        "\n                complete billing information\n            "
                      )
                    ]
                  ),
                  _vm._v(
                    "\n            to activate your paid account.\n        "
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.notShowNoticePage && (_vm.haveOrder || _vm.accountShow)
            ? _c(
                "v-btn",
                {
                  staticStyle: { position: "absolute", right: "0", top: "5px" },
                  attrs: { icon: "", small: "" },
                  on: { click: _vm.clear }
                },
                [
                  _c("v-icon", { attrs: { color: "accent" } }, [
                    _vm._v("close")
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }