import cookie from '@/module/utils/cookie'
import axios from "axios";
const $http =axios.create({
    baseURL : '/v1',
})
$http.interceptors.request.use(function (config) {
    let token = cookie.get('token')
    if(token){
        config.headers['Authorization'] = `Token ${token}`
    }
    config.headers['content_type'] = 'application/octet-stream'
    return config;
}, function (error) {
    return Promise.reject(error);
});

const download = function(href){
    $http({
        method: 'get',
        url: href,
        responseType: 'blob'
    }).then((res) => {
        if (!res) {
            return
        }
        let {headers} = res
         let [,attachment] =  headers['content-disposition'].split(';')
        let [,filename] = attachment.split('=')
        if(window.navigator.msSaveOrOpenBlob){
            // 兼容ie11
            let blobObject = new Blob([res.data]);
            window.navigator.msSaveOrOpenBlob(blobObject, filename);
        }else{
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
        }
    } )
}

export {download}
