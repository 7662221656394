// import jsrsasign from 'jsrsasign'
import $http from '@/module/http'

let info = null
let countries = null


export default {
    strict: true,
    namespaced: true,
    state: {
        unpaid:0,
        clientInfo: {
            id: ''
        }
    },
    getters : {
        checkInfoCompleted : (state) => {
            return () => {
                let arr = Object.keys(state.clientInfo);
                let validatearr = ['email', 'first_name', 'last_name', 'phone_number', 'country', 'state', 'city', 'address1','postcode'];
                let flag = true;
                arr.map((key) => {
                    if (validatearr.indexOf(key) !== -1 && !state.clientInfo[key]) {
                        flag = false
                    }
                });
                return flag;
            }
        }
    },
    mutations: {
        setClientInfo(state, payload) {
            state.clientInfo = payload
        }
    },
    /**
     * @params  flag  {Boolean} whether force to refresh info
     * */
    actions: {
        getClientInfo: ({rootState, commit}, flag) => {
            if (!flag && info) {
                return info
            }
            // let JWT = jsrsasign.KJUR.jws.JWS.parse(rootState.oAuth.token).payloadObj
            info = $http.get(`/clients/${rootState.oAuth.clientId}/`).then(res => {
                commit('setClientInfo', res)
            })
            return info
        },
        getCountries : () => {
            if(!countries){
                countries = $http.get(`/countries/`,{
                    params: {
                        page_size:300
                    }
                }).then(res => {
                    return Promise.resolve(res.results)
                })
            }
            return countries
        },
        getUnPaid:({ state, commit }) => {
            return $http.get('/invoices/', {
                params: {
                    client_id: state.clientInfo.id,
                    page_size: 999,
                    status: 'Unpaid',
                }
            }).then((res) => {
                commit('setState', {
                    module: 'client',
                    payload: {
                        unpaid: res.total
                    }
                }, {root: true})
                return Promise.resolve(res.total)
            })
        },
        getFreeTrialStatus:({state}) => {
            return $http({
                method: 'get',
                url: `/clients/${state.clientInfo.id}/status/`
            })
        },
        // checkInfoCompleted:({state})=> {
        //     let arr = Object.keys(state.clientInfo);
        //     let validatearr = ['email', 'first_name', 'last_name', 'phone_number', 'country', 'state', 'city', 'address1','postcode'];
        //     let flag = true;
        //     arr.map((key) => {
        //         if (validatearr.indexOf(key) !== -1 && !state.clientInfo[key]) {
        //             flag = false
        //         }
        //     });
        //     return flag;
        // }
    }
}
