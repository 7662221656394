<template>
    <v-dialog v-model="dialog" persistent :max-width="800"  no-click-animation lazy scrollable>
        <v-card>
            <v-card-title class="mx-2" style="justify-content: center">
                <div class="font-weight-regular" style="font-size: 24px" >
                    Completing Billing Information
                </div>
                <div class="mt-2 font-weight-regular" style="text-align: center;font-size: 16px;">
                    This is a paying order as you have run out of trial packages. Please submit the billing information to activate your paid account.
                </div>
            </v-card-title>
            <v-card-text>
                <billing-info-form v-if="dialog" ref="billingform" ></billing-info-form>
            </v-card-text>
            <v-card-actions class="mx-3 mb-3">
                <v-spacer></v-spacer>
                <v-btn class="text-none" large  outline color="blue darken-1" flat @click="close">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="text-none"  large color="primary" :loading="loading" @click="updateBillingInfo">Submit</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
b
<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    // import {mapGetters} from 'vuex'
    import rules from '@/module/rules/index.js'
    import BillingInfoForm from './BillingInfoForm.vue'


    export default {
        name: "UpdateBillingInfo",
        mixins: [loadingMixin],
        components: {BillingInfoForm},
        data() {
            return {
                rules
            }
        },
        computed: {
        },
        methods: {
            updateBillingInfo() {
                if (!this.$refs.billingform.$validate()) {
                    return
                }
                this.loading  = true
                this.$refs.billingform.submit().then(()=>{
                    this.$message.success('Your Billing Information was updated successfully!')
                    this.loading = false
                    this.close()
                })
            },

            // autoVerifyResult() {
            //
            //     // if (res.status === 'Uncertain' || (res.status === 'Good' && this.clientInfo.status !== 'Good')) {
            //     //     // this.dialog = true
            //     // } else {
            //     //     this.$message.success('Your Billing Information was updated successfully!')
            //     // }
            // },
        }
    }
</script>

<style lang="scss">

</style>
