export default {
    required: value => !!value || 'Required.',
    arrayRequired: value => value.length!==0 || 'Required.',
    lessCounter: value => !value || value.length <= 10 || 'Maximum 10 characters',
    counter32: value => !value || value.length <= 32 || 'Maximum 32 characters',
    noNumOnly :value => {
        const pattern =  /^\d+$/ ;
        return  !value || !pattern.test(value)|| 'Can not only contain numbers'
    },
    n_or_c: value => {
        const pattern =  /^[a-z0-9]+$/ ;
        return !value || pattern.test(value) || 'Can only contain lowercase characters and numbers'
    },
    characterStart: value => {
        const  pattern = /^[a-zA-Z]/
        return !value || pattern.test(value) || 'Can only start with a character'
    },
    moreCounter: value => !value || value.length >= 8 || 'Minimum 8 characters',
    n_and_c: value => {
      const  pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])/
      return !value || pattern.test(value) || 'Must contain both characters and numbers'
    },
    n_c_C: value => {
        const  pattern = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d!#%&()*+,-./:;<=>?@[\]^_{|}~$]*$/
        return !value || pattern.test(value) || 'Must Contain both uppercase and lowercase letters and numbers'
    },
    email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !value || pattern.test(value) || 'Invalid email.'
    },
    phone: value => {
        const pattern = /^(\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/
        return !value || pattern.test(value) || 'Invalid phone number.'
    },
    url: value => {
        const pattern = /^((https|http)?:\/\/)?(([0-9a-z_!~*'().&amp;=+$%-]+: )?[0-9a-z_!~*'().&amp;=+$%-]+@)?((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5]$)|([0-9a-z_!~*'()-]+\.)*([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\.[a-z]{2,6})(:[0-9]{1,4})?((\/?)|(\/[0-9a-zA-Z_!~*'().;?:@&amp;=+$,%#-]+)+\/?)$/
        return !value || pattern.test(value) || 'Invalid URL.'
    },
    domain: value => {
        const pattern = /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/
        return !value ||pattern.test(value) || 'Domain name is invalid, please re-input.(e.g., example.com)'
    },
    ip: value => {
        const pattern = /^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[1-9])\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$/
        return !value || pattern.test(value) || 'IP format error, please re-input.'
    },
    ipCidr: value => {
        const pattern =/^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[1-9])\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$|^(?:(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\/([1-9]|[1-2]\d|3[0-2])$/
        return !value || pattern.test(value) || 'IP format error, please re-input.'
    },
    number0: value =>{
        const pattern = /^[1-9]\d*|0$/
        return pattern.test(value) || 'Please set the priority to any positive integer or zero.'
    },
    notSpecialSymbol(pattern=/^(?=[0-9a-zA-Z*.]+$)/){
        return (value) => {
            return !value||pattern.test(value)||'Invalid format, please re-input.'
        }
    },
    maxCounter(num=30) {
        return value => !value || value.length <= num || `Maximum ${num} characters`
    }
}
